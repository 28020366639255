.accordion {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    background: #fafcfa;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;

    & p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #020e24;
    }

    &__actions {
      display: flex;
      gap: 30px;

      &__edit {
        display: flex;
        gap: 10px;

        & p {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #020e24;
        }
      }
    }
  }

  &__content {
    -webkit-transition: max-height 0.5s;
    -moz-transition: max-height 0.5s;
    -ms-transition: max-height 0.5s;
    -o-transition: max-height 0.5s;
    transition: max-height 0.5s;
    overflow: hidden;
    // "height: 0" not work with css transitions
    max-height: 0;

    &__inner {
      padding-left: 16px;
      margin-bottom: 16px;
      display: flex;
      gap: 10px;

      &__key {
        font-weight: 600;
        font-size: 16px;
        color: #020e24;
      }

      &__val {
        font-weight: 400;
        font-size: 16px;
        color: #020e24;
        opacity: 0.7;
      }
    }
  }
}

.accordion__content-show {
  max-height: 300px;
}
