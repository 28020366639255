*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

a {
  text-decoration: none;
  color: #fff;
  font-family: 'Source Sans Pro', sans-serif;

  &:hover {
    color: #4b8e64 !important;
  }
}

button {
  cursor: pointer;
  border: none;

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &:hover {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
}
