.modal-cancel {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3px;
  cursor: pointer;
}

.login-modal {
  &__heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    & img {
      margin-bottom: 24px;
    }

    & h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      text-align: center;
      color: #010c15;
      margin-bottom: 8px;

      @media (max-width: 700px) {
        font-size: 16px;
        line-height: 32px;
      }
    }

    & p {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: rgba(1, 12, 21, 0.7);
      text-align: center;
    }
  }
}
