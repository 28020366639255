.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.32);
  padding-bottom: 16px;
  z-index: 9999;

  .right {
    display: flex;
    gap: 32px;
    align-items: center;

    @media (max-width: 780px) {
      // display: none;
      position: absolute;
      top: 100px;
      left: 0;
      // stacks the li tags vertically
      flex-direction: column;
      // makes menu span full height and width
      width: 100%;
      height: calc(100vh - 77px);
      background-color: #4b8e64;
      border-top: 1px solid black;
      padding-top: 100px;
      z-index: 9999;
      position: fixed;

      // & a {
      //   color: #4b8e64 !important;
      //   font-size: 16px;
      //   text-decoration: none;
      // }
    }

    & a {
      color: #fff;
      font-size: 16px;
      text-decoration: none;
    }

    &__btn {
      width: 113px;
      height: 48px;
      color: #fff;
      background: #4b8e64;
      border-radius: 4px;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .right-mobile {
    display: none;

    @media (max-width: 780px) {
      // display: none;
      position: absolute;
      top: 100px;
      left: 0;
      // stacks the li tags vertically
      flex-direction: column;
      // makes menu span full height and width
      width: 100%;
      height: calc(100vh - 77px);
      background-color: #4b8e64;
      border-top: 1px solid black;
      padding-top: 100px;

      // & a {
      //   color: #4b8e64 !important;
      //   font-size: 16px;
      //   text-decoration: none;
      // }
    }

    & a {
      color: #fff;
      font-size: 16px;
      text-decoration: none;
    }

    &__btn {
      width: 113px;
      height: 48px;
      color: #fff;
      background: #4b8e64;
      border-radius: 4px;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.nav-secondary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(16, 17, 16, 0.32);
  padding-bottom: 16px;

  .right-secondary {
    display: flex;
    gap: 32px;
    align-items: center;

    @media (max-width: 780px) {
      // display: none;
      position: absolute;
      top: 100px;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: calc(100vh - 77px);
      background-color: #4b8e64;
      border-top: 1px solid black;
      padding-top: 100px;

      // & a {
      //   color: #4b8e64 !important;
      //   font-size: 16px;
      //   text-decoration: none;
      // }
    }

    & a {
      color: #101110;
      font-size: 16px;
      text-decoration: none;
    }

    &__btn {
      width: 113px;
      height: 48px;
      color: #fff;
      background: #4b8e64;
      border-radius: 4px;
      border: none;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

.logo-action {
  display: flex;
  align-items: center;
  cursor: pointer;

  & img {
    width: 40px;
    height: 40.18px;
  }
}

.logo-action-wrapper {
  position: relative;

  .drop {
    position: absolute;
    //width: 100%;
    padding: 16px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // height: 100px;
    background: #fff;
    right: 8%;
    top: 110%;

    & p {
      cursor: pointer;
    }
  }
}

.bars {
  display: none;

  @media (max-width: 780px) {
    display: block;
  }
}
// .mobile-nav {
//   display: none !important;

//   @media (max-width: 780px) {
//     display: flex !important;
//   }
// }

.nav-hide {
  display: none !important;
}

.web-nav {
  display: flex !important;

  @media (max-width: 780px) {
    display: none !important;
  }
}

.mobile-reg-btn {
  width: 113px;
  height: 48px;
  color: #4b8e64;
  background: #fff;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.header-logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
