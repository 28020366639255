table {
  width: 100%;
}

thead {
  height: 50px;
  background: #edfff4;
}
thead > tr > th {
  text-align: left;
  padding-left: 24px;
}

tbody > tr > td {
  text-align: left;
  padding-left: 24px;
  padding-top: 34px;
  padding-bottom: 34px;

  & p {
    width: 120px;
  }
}
