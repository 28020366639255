.breadcrumbs {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 39px;

  &__text {
    display: flex;
    gap: 10px;

    &__prev {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 21px;
      cursor: pointer;
      color: rgba(1, 12, 21, 0.7);
      margin-bottom: 0;
    }

    &__active {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: rgba(1, 12, 21, 1);
      margin-bottom: 0;
    }
  }
}
