.payment-wrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: 780px) {
    flex-direction: column;
  }

  &__right {
    background: #edfff4;
    padding: 80px 60px;
    width: 50%;

    @media (max-width: 950px) {
      padding: 80px 20px;
    }

    @media (max-width: 780px) {
      width: 100%;
    }

    @media (max-width: 780px) {
      display: none;
    }

    & h2 {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #010c15;
    }

    &__summary {
      margin-top: 40px;
      border-bottom: 1px solid #c7c9c9;
      padding-bottom: 32px;
      margin-bottom: 23px;

      &__item {
        display: flex;
        justify-content: space-between;
        width: 70%;
        align-items: center;
        margin-bottom: 16px;

        @media (max-width: 1200px) {
          width: 100%;
        }

        &__left {
          font-weight: 400;
          font-size: 24px;
          color: #010c15;
          margin-bottom: 0;

          @media (max-width: 950px) {
            font-size: 16px;
          }
        }

        &__right {
          font-weight: 600;
          font-size: 16px;
          color: #010c15;
          margin-bottom: 0;
        }
      }
    }
  }

  &__left {
    padding: 80px;

    @media (max-width: 780px) {
      padding: 16px;
    }

    &__heading {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 32px;

      & img {
        width: 80px;
        height: 80px;
      }

      & h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #010c15;
      }

      & p {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: rgba(1, 12, 21, 0.7);
      }
    }

    &__form {
      &__ato {
        & label {
          font-family: 'Source Sans Pro';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #010c15;
        }

        &__flex {
          display: flex;
          gap: 16px;
          margin-top: 8px;

          &__btn {
            width: 50%;
            display: flex;
            height: 40px;
            justify-content: space-between;
            align-items: center;
            padding: 0px 20px;
            background: #fafafa;
            border: 1px solid rgba(1, 12, 21, 0.1);
            border-radius: 4px;

            & p {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: rgba(1, 12, 21, 0.2);
              font-family: 'Source Sans Pro';
            }
          }

          &__btn-selected {
            width: 50%;
            display: flex;
            height: 40px;
            justify-content: space-between;
            align-items: center;
            padding: 0px 20px;
            background: #fafafa;
            border: 1px solid rgba(1, 12, 21, 0.1);
            border-radius: 4px;

            & p {
              font-weight: 600;
              font-size: 14px;
              line-height: 21px;
              color: #010c15;
              font-family: 'Source Sans Pro';
            }
          }
        }
      }
    }
  }
}

.outline {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 8px;

  & p {
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #ce7c21;
  }
}
