.home {
  background: url('../../assets/hero-bg.png');
  width: 100%;
  height: 768px;
  padding: 32px 80px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 780px) {
    padding: 16px;
  }

  &__hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;

    & h1 {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 700;
      font-size: 56px;
      line-height: 84px;
      text-align: center;
      color: #ffffff;

      @media (max-width: 780px) {
        font-size: 38px;
        line-height: 50px;
      }

      @media (max-width: 340px) {
        font-size: 30px;
        line-height: 40px;
      }
    }

    & p {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 300;
      font-size: 24px;
      line-height: 36px;
      color: #ffffff;
      margin-top: 16px;
      text-align: center;

      @media (max-width: 780px) {
        font-size: 20px;
      }

      @media (max-width: 340px) {
        font-size: 16px;
      }
    }

    &__input {
      width: 70%;
      height: 60px;
      background: #ffffff;
      border-radius: 8px;
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 16px;

      @media (max-width: 500px) {
        width: 100%;
      }

      & input {
        border: none;
        width: 90%;
        height: 100%;
        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 300;
        font-size: 20px;

        &:focus {
          outline: none !important;
        }
      }
    }
  }

  &__actions-list {
    // height: 768px;
    background: #efefef;
    padding: 80px;
    width: 100%;

    @media (max-width: 1098px) {
      padding: 30px;
    }

    &__title {
      display: flex;
      align-items: center;
      justify-content: center;

      & h2 {
        text-align: center;
        font-weight: 600;
        font-size: 48px;
        line-height: 72px;
        text-align: center;
        color: #101110;

        @media (max-width: 680px) {
          font-size: 32px;
          line-height: 36px;
        }

        @media (max-width: 500px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    &__cards-wrapper {
      margin-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 34px;
    }
  }

  &__speech {
    padding: 80px 120px;

    @media (max-width: 594px) {
      padding: 80px 32px;
    }

    &__title {
      margin-bottom: 49px;

      & h2 {
        font-family: 'Source Sans Pro', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 48px;
        line-height: 72px;
        color: #101110;

        @media (max-width: 1150px) {
          text-align: center;

          & br {
            display: contents;
          }
        }

        @media (max-width: 594px) {
          font-size: 32px;
          line-height: 40px;
        }
      }
    }

    &__flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 60px;

      @media (max-width: 1150px) {
        flex-direction: column;
      }

      &__right {
        // flex: 1;
        background: url('../../assets/logo-new.jpg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 557px;
        height: 442px;
        border-radius: 8px;
        display: flex;
        align-items: flex-end;
        background-position: center;

        @media (max-width: 594px) {
          width: 100%;
          background-size: cover;
        }

        &__name {
          width: 557px;
          height: 92px;
          background: rgba(11, 14, 12, 0.27);
          backdrop-filter: blur(13px);
          border-radius: 0px 0px 8px 8px;
          padding: 14px 40px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          @media (max-width: 594px) {
            height: 100px;
          }

          & h4 {
            font-weight: 500;
            font-size: 24px;
            line-height: 32px;
            color: #ffffff;

            @media (max-width: 594px) {
              font-size: 20px;
              line-height: 30px;
            }
          }

          & p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #ffffff;
          }
        }
      }

      &__left {
        flex: 1;

        & p {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.02em;
          color: #101110;
          text-align: justify;
        }
      }
    }
  }

  &__cta {
    width: 100%;
    height: 214px;
    background: #4b8e64;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px;

    @media (max-width: 700px) {
      padding: 30px;
    }

    @media (max-width: 379px) {
      flex-direction: column;
    }

    & h2 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 48px;
      line-height: 72px;
      color: #ffffff;

      @media (max-width: 700px) {
        font-size: 32px;
      }

      @media (max-width: 500px) {
        line-height: 50px;
      }

      @media (max-width: 379px) {
        text-align: center;
        font-size: 24px;
      }
    }

    &__btn {
      width: 113px;
      height: 48px;
      font-family: 'Source Sans Pro', sans-serif;
      background: #ffffff;
      border-radius: 4px;
      color: #4b8e64;
      font-weight: 600;
      font-size: 16px;
    }
  }
}

.search-results {
  width: 70%;
  max-height: 241px;
  background: #ffffff;
  border: 1px solid rgba(1, 12, 21, 0.1);
  box-shadow: -4px 4px 13px 4px rgba(118, 128, 135, 0.08);
  border-radius: 4px;
  z-index: 1000;
  overflow: scroll;

  &__heading {
    display: flex;
    justify-content: space-between;
    padding: 11px 24px;

    & p {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
    }
  }

  &__body {
    &__item {
      display: flex;
      justify-content: space-between;
      padding: 11px 24px;
      cursor: pointer;

      &:hover {
        background: #e4eff9;
        border-left: 3px solid #449adf;
      }

      & p {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #010c15;
        margin: 0;

        &:first-child {
          text-align: justify;
          width: calc(100% / 3);
        }

        &:last-child {
          text-align: right;
          width: calc(100% / 3);
        }
      }

      &__approved {
        display: flex;
        align-items: center;
        gap: 10px;
        width: calc(100% / 3);
        margin-left: auto;

        & p {
          line-height: 18px;
          color: rgba(1, 12, 21, 0.7);
        }
      }
    }
  }

  @media (max-width: 500px) {
    width: 100%;
  }
}

.home-spinner > div {
  height: 100px;
}
