.register__heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(1, 12, 21, 0.1);
  width: 80%;
  margin-bottom: 32px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &__title {
    display: flex;
    gap: 12px;
    align-items: center;
    height: 100%;
    padding-bottom: 12px;
    cursor: pointer;
  }
}

.active-heading {
  border-bottom: 2px solid #4b8e64;

  & p {
    font-size: 16px;
    line-height: 24px;
    color: #4b8e64;
  }
}

.ato-check {
  display: flex;
  gap: 10px;
  align-items: center;

  & p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ce7c21;
  }
}

.upload-done {
  margin-right: auto;
  padding: 16px 0;

  &__image-name {
    width: 100%;
    height: 54px;
    background: #ffffff;
    box-shadow: -4px 4px 13px 4px rgba(118, 128, 135, 0.08);
    border-radius: 4px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-items: center;
    padding-left: 20px;

    &__details {
      & p {
        &:first-child {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: #010c15;
        }
      }
    }
  }
}

.image-drop {
  background: #fafafa;
  border: 1px solid rgba(1, 12, 21, 0.1);
  border-radius: 4px;
  width: 100%;
  padding-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  cursor: pointer;
  flex-direction: column;
  margin-top: 8px;
  height: 200px;

  &__dash {
    border: 1px dashed #eee;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      margin: 0 auto;
    }
  }

  & img {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input {
    height: 100%;
    width: 100%;
  }
}

.ato-upload {
  margin-top: 24px;

  & label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #010c15;
  }
}

.double-btn-flex {
  display: flex;
  gap: 80px;
}

.information-payment-summary {
  margin-top: 16px;
  & h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #010c15;
  }

  &__text {
    display: flex;
    margin: 8px 0;
    gap: 5px;

    &__left {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: rgba(1, 12, 21, 0.7);
    }

    &__right {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: rgba(1, 12, 21, 0.7);
    }
  }
}

.remita-btn {
  background: none;
  color: #fff;
  font-size: 16px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
}
