.auth-wrapper {
  display: flex;

  &__left {
    background-image: url('../../assets/auth-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    padding: 200px 60px;
    width: 45%;
    height: 100vh;

    @media (max-width: 1000px) {
      display: none;
    }

    & h2 {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 52px;
      color: #ffffff;
      margin-bottom: 32px;

      @media (max-width: 1024px) {
        font-size: 32px;
        br {
          display: none;
        }
      }
    }

    &__text {
      display: flex;
      gap: 15px;
      & p {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
  }

  &__right {
    width: 55%;
    padding-top: 200px;
    padding-left: 60px;
    padding-right: 100px;
    height: 100vh;
    overflow: scroll;

    @media (max-width: 1325px) {
      width: 80%;
    }

    @media (max-width: 1024px) {
      padding-right: 30px;
    }

    @media (max-width: 1000px) {
      width: 100%;
      padding: 60px;
    }

    @media (max-width: 500px) {
      padding: 30px;
    }
    &__content {
      & h3 {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #010c15;
      }

      & p {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(1, 12, 21, 0.7);
        margin-bottom: 33px;
      }
    }
  }
}
