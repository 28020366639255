.pagination {
  //background: #f4f4f6;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  align-items: center;
  margin-top: 50px;

  &__left {
    & p {
      margin: 0;
      color: #707885;
      font-family: var(--font-light);
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 20px;

    & p {
      margin: 0;
      color: #707885;
      font-family: var(--font-light);
    }

    & select {
      border: 0.5px solid #dbdbdb;
      border-radius: 5px;
      font-family: var(--font-light);
      padding: 5px 0;

      &:focus {
        outline: none;
      }
    }
  }
}

.arrow-icons {
  display: flex;
  gap: 20px;

  & button {
    border: none;
    background: none;
    border: 1px solid #eee;
    width: 30px;
    height: 30px;
    border-radius: 4px;

    &:hover {
      transition: all 0.2s;
      background-color: rgba(0, 0, 0, 0.06);
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}

.active-pagination {
  border: 1px solid #4c8d63 !important;
}
