.students-list {
  padding: 32px 80px;
  overflow: hidden;

  @media (max-width: 1000px) {
    padding: 20px;
  }

  &__body {
    margin-top: 40px;
  }
}

.empty-students {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 100px 0;

  &__title {
    & h2 {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 48px;
      color: #010c15;
      margin-bottom: 16px;

      @media (max-width: 650px) {
        font-size: 32px;
      }
    }

    & p {
      font-family: 'Source Sans Pro';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: rgba(1, 12, 21, 0.7);
    }
  }

  &__body {
    padding-top: 70px;

    & h4 {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #010c15;
    }

    & p {
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      color: rgba(1, 12, 21, 0.7);
    }

    &__btn {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.student-list {
  background: #ffffff;
  border: 1px solid rgba(1, 12, 21, 0.1);
  box-shadow: 4px 4px 24px 8px rgba(236, 245, 240, 0.84);
  border-radius: 8px;
  padding: 32px 40px;

  &__heading {
    display: flex;
    justify-content: space-between;

    @media (max-width: 700px) {
      flex-direction: column;
    }

    &__left {
      display: flex;
      flex-direction: column;
      gap: 16px;
      & h3 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #010c15;

        @media (max-width: 700px) {
          font-size: 14px;
        }
      }

      & p {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: rgba(1, 12, 21, 0.7);
      }
    }
  }

  &__summary-cards {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 20px;

    &__card {
      width: 280px;
      height: 94px;
      display: flex;
      gap: 10px;
      background: #edfff4;
      border: 1px solid #e4eff9;
      border-radius: 4px;
      padding: 16px;

      @media (max-width: 650px) {
        width: 100%;
      }

      &__text {
        & h3 {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 18px;
          color: rgba(1, 12, 21, 0.7);
        }

        & h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 36px;
          color: #010c15;
        }
      }
    }
  }

  &__table {
    margin-top: 40px;
    overflow: scroll;

    &__filter {
      display: flex;
      gap: 24px;
      border-bottom: 1px solid rgba(1, 12, 21, 0.1);
      height: 40px;
      margin-bottom: 24px;

      &__tag {
        display: flex;
        gap: 14px;
        cursor: pointer;

        & p {
          font-weight: 600;
          font-size: 14px;
          line-height: 21px;
          color: rgba(1, 12, 21, 0.7);
          opacity: 0.7;

          @media (max-width: 450px) {
            width: 100px;
          }
        }

        &__badge {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #f6f6f6;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          color: rgba(1, 12, 21, 0.7);
        }
      }
    }
  }
}

.filter-active {
  border-bottom: 2px solid #4b8e64;
}

.generated-badge {
  width: 111px;
  height: 25px;
  background: #95eb6d;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-generated-badge {
  width: 111px;
  height: 25px;
  background: #ffc3c3;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.generate-flex {
  display: flex;
  gap: 5px;
  cursor: pointer;

  & p {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #4b8e64;
  }
}

.id-card-back {
  height: 500px;

  & img {
    height: 100%;
  }
}

.id-card {
  background-image: url('../../assets/id-card2.png');
  // width: 300px;
  height: 500px;
  background-repeat: no-repeat;
  position: relative;
  padding: 31px;

  &__sid {
    position: absolute;
    font-weight: 800;
    font-size: 24px;
    line-height: 36px;
    color: #010c15;
    top: 42%;
  }

  &__school {
    position: absolute;
    font-weight: 800;
    font-size: 14px;
    line-height: 36px;
    color: #010c15;
    top: 66%;
    text-transform: capitalize;
  }

  &__sex {
    position: absolute;
    font-weight: 800;
    font-size: 14px;
    line-height: 36px;
    color: #010c15;
    top: 77%;
    text-transform: capitalize;
  }

  &__last_name {
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #010c15;
    top: 57%;
    text-transform: capitalize;
  }

  &__date {
    position: absolute;
    font-weight: 800;
    font-size: 14px;
    line-height: 36px;
    color: #010c15;
    top: 77%;
    text-transform: capitalize;
    left: 23%;
  }

  &__other_names {
    position: absolute;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #010c15;
    top: 57%;
    text-transform: capitalize;
    left: 23%;
  }
}

.bulk-actions {
  display: flex;
  gap: 16px;
}
