@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap');

.ato-modal {
  position: relative;

  & p {
    position: absolute;
    top: 32%;
    left: 30%;
    font-family: 'Great Vibes', cursive;
    font-size: 32px;
    text-transform: capitalize;

    @media (max-width: 780px) {
      top: 34%;
      left: 35%;
      font-size: 32px;
    }

    @media (max-width: 450px) {
      left: 20%;
      font-size: 16px;
    }
    @media (max-width: 500px) {
      left: 30%;
    }

    @media (max-width: 370px) {
      top: 32%;
      font-size: 16px;
    }
  }
  & img {
    width: 100%;
  }
}
