.input-component {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;

  & label {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #010c15;
  }
}

.input-field {
  width: 100%;
  height: 48px;
  text-indent: 16px;
  background: #fafafa;
  border: 1px solid rgba(1, 12, 21, 0.1);
  border-radius: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 16px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(1, 12, 21, 0.2);
  }

  &:focus {
    outline: none;
    border: 1px solid #4b8e64;
  }
}

.textarea-field {
  width: 100%;
  height: 88px;
  padding-left: 16px;
  background: #fafafa;
  border: 1px solid rgba(1, 12, 21, 0.1);
  border-radius: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 16px;
  padding-top: 6px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(1, 12, 21, 0.2);
  }

  &:focus {
    outline: none;
    border: 1px solid #4b8e64;
  }
}

.error-msg {
  color: #cc0000;
}

.error-field {
  width: 100%;
  height: 48px;
  text-indent: 16px;
  background: #fafafa;
  border: 1px solid #cc0000;
  border-radius: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 16px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(1, 12, 21, 0.2);
  }

  &:focus {
    outline: none;
    border: 1px solid #cc0000;
  }
}

.textarea-error {
  width: 100%;
  height: 88px;
  text-indent: 16px;
  background: #fafafa;
  border: 1px solid #cc0000;
  border-radius: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 16px;
  padding-top: 6px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(1, 12, 21, 0.2);
  }

  &:focus {
    outline: none;
    border: 1px solid #cc0000;
  }
}

.password-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  text-indent: 16px;
  background: #fafafa;
  border: 1px solid rgba(1, 12, 21, 0.1);
  border-radius: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 16px;
  padding-right: 12px;

  & input {
    background: none;
    border-radius: 0;
    border: none;
    width: 100%;
    text-indent: 16px;
    height: 100%;

    &:focus {
      outline: none;
      border: none;
    }
  }

  & p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(1, 12, 21, 0.7);
    cursor: pointer;
  }
}
