.payment-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & img {
    margin-bottom: 16px;
  }

  &__close {
    margin-left: auto;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.w-100 {
  width: 100% !important;
}
