.action-card {
  display: flex;
  gap: 16px;
  width: 583px;
  height: 152px;
  background: #ffffff;
  border-radius: 8px;
  padding: 32px;
  cursor: pointer;

  @media (max-width: 1365px) {
    width: 500px;
  }

  @media (max-width: 1200px) {
    width: 450px;
    height: 160px;
  }

  @media (max-width: 450px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &:hover {
    background: rgba(49, 255, 126, 0.13);
    transition: 0.2s ease-out;

    &__icon {
      background: #fff !important;
    }
  }

  &__icon {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 450px) {
      & svg {
        width: 32px;
        height: 32px;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    gap: 16px;

    & h3 {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      color: #101110;

      @media (max-width: 500px) {
        font-size: 24px;
        line-height: 30px;
      }

      @media (max-width: 450px) {
        text-align: center;
      }
    }

    & p {
      font-family: 'Source Sans Pro', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #101110;

      @media (max-width: 500px) {
        font-size: 14px;
      }

      @media (max-width: 450px) {
        text-align: center;
      }
    }
  }
}
